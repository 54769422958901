<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('project.Equipment-detail')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <!-- <el-divider content-position="center">{{$t('project.regular-data')}}</el-divider> -->
                     <el-form-item v-for="item in attributeList" :key="item.id" :label="item.code"  >
                        <el-input v-model="form[item.code]" ></el-input>
                    </el-form-item>
                </el-col>
                
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminDevice'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {

    },
    data() {
        return {
            form:{
            },
            deviceId: this.$route.query.id,
            regions:[],
            countrys:[],
            defaultGridIDs:[5,6,13],
            deviceCode:sessionStorage.getItem('deviceCode'),
            attributeList:[]
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.deviceId){
            // this.theatpumpGetById()
            let param = sessionStorage.getItem("paramJson")
            param = JSON.parse(param)
            let paramKeys = Object.keys(param)
            console.log(paramKeys);
            paramKeys.forEach((item)=>{
                this.attributeList.push({code:item})
            })
            this.form = Object.assign(param)
        }else{
            this.GetDictionaryBatch()
        }
        
    },
    methods: {
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },

        async GetDictionaryBatch() {
            let res = await this.API.tdictionaryGetDictionaryBatch({
                lang: localStorage.getItem("lan"),
                dictCodes: [this.deviceCode],
            });
            if (res == '') {
                this.attributeList = []
                return
            }
            this.attributeList = res[this.deviceCode]
        },
        
      

        /*保存提交*/
        saveForm(formName){
            let $this = this
            if(this.deviceId){
                $this.update($this.form)
            }else {
                $this.save($this.form)
            }
        },

        /*更新*/
        async update(data){
            let paramJson = JSON.stringify(data)
            let item = {
                paramJson,
                deviceTypeCode:this.$route.query.deviceTypeCode,
                deviceCode:this.$route.query.deviceCode,
                id:this.$route.query.id
            }
            let res = await this.API.otherdeviceUpdate(item)
            // this.showMessage(res)
            if (res.code == 1 ) {
                this.$message({
                    message: "机型参数已存在",
                    type: 'warning'
                });
            }else if(res.code == 2){
                this.$message({
                    message: "程序错误",
                    type: 'warning'
                });
            }else{
                this.showMessage(res)
            }

        },

        /*创建*/
        async save(data){
            // console.log(this.$route.query.deviceTypeCode);
            let paramJson = JSON.stringify(data)
            let item = {
                paramJson,
                deviceTypeCode:this.$route.query.deviceTypeCode,
                deviceCode:this.$route.query.deviceCode,
            }
            let res = await this.API.otherdeviceSave(item)
            if (res.code == 1 ) {
                this.$message({
                    message: "机型参数已存在",
                    type: 'warning'
                });
                this.$router.push({path: '/adminDevice'});
            }else if(res.code == 2){
                this.$message({
                    message: "程序错误",
                    type: 'warning'
                });
                this.$router.push({path: '/adminDevice'});
            }else{
                this.showMessage(res)
            }
            
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminDevice'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
    
</style>

